import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import { AuthService } from './core/auth.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  db = firebase.default.app().firestore()
  url = "https://ioanalyticservices-ldoxyjbqhq-uc.a.run.app/"
  constructor(private _http: HttpClient, private afs: AngularFirestore) {
  }



  public getDeviceData(sn: string) {
    return this._http.post(`${this.url}serviceData`, { "sn": sn });
  }



  public getCollectionData(collectionName: string = "System", documentName: string = "referenceValues") {

    let url = "https://iogeneralservices-ldoxyjbqhq-uc.a.run.app/getcollectiondata";

    return this._http.post(url, { "collection": collectionName, "doc": documentName });


  }


  public filterData(data) {

    const url = "https://iostatistic-ldoxyjbqhq-uc.a.run.app/u_statistics"      // Call for generalservices (nodejs server implementation)
    //const url = "http://localhost:8080/u_statistics"
    //array = {"x": x_array, "y": y_array}

    return this._http.post(url, { "array": data });


  }

  getDeviceFac(SN: string): Promise<any> {
    return new Promise((res, rej) => {
      this.getCollectionData("Devices", SN).pipe(
        map((val: any) => {

          return val.data != null ? val.data.usingFacility?.facilityId : undefined
        })
      ).subscribe(facId => {
        this.getCollectionData("SQACustomers", facId).subscribe((result: any) => {
          res(result.data)
        })
      })
    })
  }

  async checkingDistributor(email: string) {
    return new Promise((response, rej) => {
      this.db.collection("Distributers").where('email', '==', email).get().then(res => {
        if (res.docs.length!=0) {
          response(res.docs[0].data())
        }else{
          this.db.collection("MESUsers").where('email', '==', email).where('adminType','==','Admin (all permissions)').get().then(admin => {
            response(admin.docs[0].data())
          })
        }
      })
    })

  }



}
