import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { user } from '../globals/globals';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: any;

  constructor(
    private _afAuth: AngularFireAuth,
    private _afs: AngularFirestore,
    private _http: HttpService,
    private _router: Router,
    private httpClient: HttpClient,
  ) { }

  signInWithEmailandPwd(user: user, queryToken?: string): Promise<string> {

    return new Promise(async (response, rej) => {
      let token = queryToken ? { token: queryToken } : undefined;
      if (token == undefined) {
        try {
          token = await this.getCustomToken({ email: user.email, password: user.password })
        } catch (error) {
          response(error.error.message.replace("_", " "))
          throw error;
        }
      }
      this._afAuth.signInWithCustomToken(token.token).then(async res => {
        let isDistributor: any = await this._http.checkingDistributor(res.user.email)
        this.currentUser = isDistributor
        if (isDistributor != undefined) {
          this.setLoggedIn(true)
          response("valid user")
        } else {
          response("not a distributor")
        }
      }).catch(err => {
        console.log("problem with login", err);
        rej("no user found")
      })
    })
  }

  async signOut() {
    return this._afAuth.signOut().then(res => {
      this._router.navigate([''])
      this.setLoggedIn(false)
      return "signed out successfully"
    }).catch(err => {
      return "problem with signout"
    })
  }

  setLoggedIn(status: boolean) {
    localStorage.setItem("login", status.toString())
  }

  isLoggedIn(): boolean {
    return localStorage.getItem("login").toLowerCase() == "true"
  }

  getCustomToken(credentials: { email: string, password: string }): Promise<{ token: string }> {
    const url = "https://iodistributorervices-ldoxyjbqhq-uc.a.run.app/getCustomToken"
    return new Promise((res, rej) => {
      this.httpClient.post(url, credentials).subscribe((result: any) => {
        if (result.code == 200) {
          res({ token: result.data })
        } else {
          rej(result)
        }
      },
        (error: any) => {
          rej(error)
        }
      )
    })
  }
}
