import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";

import { CommonModule } from "@angular/common";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { HttpService } from "../http.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment"
import { MatSort, Sort } from "@angular/material/sort";

import { GraphDialogComponent } from 'src/app/graph-dialog/graph-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from "@angular/material/input";
import { version } from '../../../package.json';
import { GlobalService } from "../global.service";
import { ReportingService } from "../service/reporting.service";
import { switchMap, retry, first, tap, map, filter } from 'rxjs/operators';
import { AuthService } from "../core/auth.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { titles } from "../globals/globals";
import { element } from "protractor";


declare const $: any;

interface TableData {
  id: string;
  testDate: string;
  type: string;
  SelfTest: string;
}

interface ParamsCriteria {
  name: string;
  title: string;
  startRange?: number;
  endRange?: number;
  limitToCheck?: number
  color: string;
  specificPercent?: number;
  recommendation: string;
  recommendationColor: string
}
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})



export class MainComponent {
  title = 'servicedataviewer';
  dataSource: MatTableDataSource<TableData>;
  serviceData: MatTableDataSource<TableData>;
  momentRef: any = moment
  filtered: any[];
  date: any;
  value = 'Clear me';
  paramsOrder = ["SelfTest", "amplitude", "amplitudeAMP", "average", "aw", "controlZeroLevel", "ledCurrent1", "ledCurrent2", "maxSpWidth", "minSpHeight", "minSpWidth",
    "noiseLevel", "ref1", "ref2", "serialNumber", "zeroLevel"]
  displayedColumns = ["detail", "testDate", "type", "SelfTest"];
  parameterColumn = ["parameters", "value", "calibrated"]
  restrictedTypes = ["Post-Production", "Final QC", "Pre-Calibration"]
  isSpinner: boolean = false

  paramsDecimals = {
    count: 1,
    ref2: 1,
    amplitude: 2,
    amplitudeAMP: 2,
    average: 2,
    averageSignalStd: 2,
    averageStd: 2,
    noiseLevelStd: 2,
    zeroLevel: 2,
    zeroLevelStdSignal: 2,
  }

  appVersion
  checkingParams: ParamsCriteria[] = [
    { name: "SelfTest", title: "Self-Test", startRange: 1, endRange: 1, color: "#A10C0C", recommendation: 'Contact our support team for further instructions.', recommendationColor: '#FFC12D' },
    { name: "SelfTest", title: "Self-Test", startRange: 1, endRange: 1, color: "#A10C0C", specificPercent: 2, recommendation: 'Contact our support team for further instructions.', recommendationColor: '#FFC12D' },
    { name: "noiseLevel", title: "Noise Level", startRange: 0, endRange: 4, color: "#A10C0C", recommendation: 'Contact our support team for further instructions.', recommendationColor: '#FFC12D' },
    { name: "ledCurrent1", title: "Led cur 1", limitToCheck: 2, color: "#A10C0C", recommendation: 'Contact our support team for further instructions.', recommendationColor: '#FFC12D' },
    { name: "ledCurrent2", title: "Led cur 2", limitToCheck: 1, color: "#A10C0C", recommendation: 'Contact our support team for further instructions.', recommendationColor: '#FFC12D' },
    { name: "ref2", title: "Ref2", startRange: 2700, endRange: 3300, color: "#A10C0C", recommendation: 'Contact our support team for further instructions.', recommendationColor: '#FFC12D' }]

  serviceDataofDevice: any = {}
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedRowIndex: any;
  postCalibrationData: any[] = []
  Alerts: { parameter: string; status: boolean; percent: number, }[] = [];
  showAlerts: boolean = false
  facility: any;
  serviceDataforReport: any;
  maintenanceReport: { data: { serviceDataParameters: any; }; };
  userName: string = ""
  isAdmin: boolean
  Alerted: any = [];
  array: any[];
  deviceNo: any;
  public browserRefresh: boolean;
  constructor(private renderer: Renderer2, private _auth: AuthService, private _router: Router, private _httpService: HttpService, private _glob: GlobalService, private domSanitizer: DomSanitizer, private _cdr: ChangeDetectorRef, private _dialog: MatDialog, private _report: ReportingService, private Route: ActivatedRoute) {
    window.onbeforeunload = () => {
      console.log("reload..............");
      this.logout();
    }
    this.isAdmin = 'adminType' in _auth.currentUser
    this.appVersion = version
    this.userName = `${_auth.currentUser.firstName} ${_auth.currentUser.lastName} ${this.isAdmin ? '(Admin)' : ''}`
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    console.log(this.isAdmin, this.userName);
    this.Route.queryParams.subscribe((params) => {
      this.deviceNo = params['sn'];
      // console.log(this.deviceNo);
      if (this.deviceNo) {
        this.getAllServicedata(this.deviceNo)
      }
    })
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  //formating the data for table
  formatServiceDataStructure(allService: any) {
    this.postCalibrationData = []
    const requiredData = (service) => {
      if (!this.restrictedTypes.includes(service.data.serviceDataType)) {
        let obj = {
          ...service.data.serviceDataParameters,
          id: service.id,
          testDate: service.data.serviceDataDate,
          type: service.data.serviceDataType,
          // SelfTest: service.data.serviceDataParameters.SelfTest == 1 ? "pass" : "fail",
        }
        if (service.data.serviceDataType == "Post-Calibration") {
          this.postCalibrationData.push(obj)
        }

        return obj
      }
    }
    return allService.data.map(requiredData).filter(element => element != undefined)
  }


  showGraph() {
    let dataForGraph = [];
    const dialogRef = this._dialog.open(GraphDialogComponent, {
      height: ' 800',
      width: '1200px',
      hasBackdrop: true,
      disableClose: true,
      data: this.serviceDataofDevice,
    });
  }


  //get all service data and formateed it for the first table
  async getAllServicedata(serialNumber) {
    if (serialNumber.length != 5) return;
    this.isSpinner = true
    this.Alerts = []
    this.showAlerts = false
    this.facility = await this._httpService.getDeviceFac(serialNumber)
    this._httpService.getDeviceData(serialNumber).subscribe(
      async (result: any) => {
        this.serviceDataofDevice = result.data
        let mappedData = this.formatServiceDataStructure(result);
        const sorted_data = await this.sortData(mappedData, "desc", "testDate")

        this.maintenanceReport = sorted_data.length > 0 ? { data: { serviceDataParameters: sorted_data[0] } } : undefined
        console.log(this.maintenanceReport);
        this._glob.date1 = this.maintenanceReport;
        this.dataSource = new MatTableDataSource<TableData>(sorted_data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isSpinner = false
        this.setCheckingParams(this.postCalibrationData)
        this.clearListdata()
      },
      (error) => {
        console.log("Error posting data : ", error);
        this.isSpinner = false
        this.clearServicedata()
        this.clearListdata()
      }
    );
  }


  //Creates a new variable, will work if there is no date object
  deepCopy(datatoCopy) {
    return JSON.parse(JSON.stringify(datatoCopy))
  }

  // takes the real(unformated) data matched the selected id
  getDataofId(id) {
    const copiedArray: any[] = this.deepCopy(this.serviceDataofDevice)
    return copiedArray.filter(el => el.id == id)[0]
  }

  //mark fields that are not in range

  //sends data to displaying table function
  displayServiceDatatable(id) {
    this.highlight(id)
    const res = this.getDataofId(id)
    console.log(res);
    console.log(res.data.serviceDataDate);
    // this._glob.date = res;
    this.formatserviceData(res).then((finalArray: any) => {
      this.serviceData = new MatTableDataSource<TableData>(this.orderData(finalArray));
      console.log(this.serviceData.data);
      this.filtered = this.serviceData.data;
      this._glob.getdocument = this.serviceData.data;
    })
  }

  //takes unformated data to process and displays it on table
  formatserviceData(datatoFormat: any): Promise<any[]> {
    return new Promise((response, reject) => {
      let finalArray = []
      let parametersObj = datatoFormat.data.serviceDataParameters
      let calibratedobj = {}
      const decimals = Object.keys(this.paramsDecimals)
      const getParamsStatus = (paramName: string, value: Number) => {
        const rangeObj: any = this._glob.parProperties
        if (paramName != "SelfTest") {
          if (Object.keys(rangeObj).includes(paramName) && rangeObj[paramName].range != undefined) {
            if (value >= rangeObj[paramName].range[0] && value <= rangeObj[paramName].range[1]) {
              return { status: false }
            } else {
              return { status: true, hint: `${rangeObj[paramName].range[0]} to ${rangeObj[paramName].range[1]}` }
            }
          } else {
            return { status: undefined }
          }
        } else {
          return value ? { status: false } : { status: true, hint: `must be 1` }
        }
      }

      //more than 1 post calibration take latest
      this.sortData(this.postCalibrationData, "desc", "testDate").then(res => {
        console.log(res);
        this._glob.date = res;
        const result = res.length > 0 ? this.getDataofId(res[0].id) : undefined
        console.log(result);
        calibratedobj = result?.data.serviceDataParameters
        console.log(calibratedobj);
        if (parametersObj) {
          for (const key in parametersObj) {
            if (Object.prototype.hasOwnProperty.call(parametersObj, key)) {
              const element = parametersObj[key];
              finalArray.push({
                parameters: key,
                value: decimals.includes(key) ? element.toFixed(this.paramsDecimals[key]) : element,
                calibrated: calibratedobj && calibratedobj[key] != undefined ? (decimals.includes(key) ? calibratedobj[key].toFixed(this.paramsDecimals[key]) : calibratedobj[key]) : "N.A",
                notInRange: getParamsStatus(key, element)
              })
            }
          }
          this.serviceDataforReport = datatoFormat
          response(finalArray)
        } else {
          reject("error")
        }
      })
    })

  }
  //setting the alert parameters to check
  setCheckingParams(calibratedobj: any[]) {
    console.log(calibratedobj);
    this.sortData(calibratedobj, 'desc', "testDate").then(res => {
      console.log(calibratedobj);
      const calibratedObj = res.length > 0 ? this.getDataofId(res[0].id).data.serviceDataParameters : undefined
      var paramLevels = this.checkingParams.map(element => {
        if (element.limitToCheck != undefined && calibratedObj != undefined) {
          return { ...element, startRange: calibratedObj[element.name] - element.limitToCheck, endRange: calibratedObj[element.name] + element.limitToCheck }
        } else {
          return element
        }
      });
      console.log(paramLevels);
      if (paramLevels != undefined) {
        this.Alerts = paramLevels.map(ele => {
          return { parameter: ele.name, ...this.parameterFailedPercent(ele.specificPercent ? this.dataFromrange(this.dataSource.data, this.momentRef().subtract(1, 'months')._d) : this.dataSource.data, ele) }
        }).filter(el => el.status)
        console.log(this.Alerts);
      }
      function getUniqueListBy(arr, key) {
        console.log(new Map(arr.map(item => [item[key], item])).values())
        return [...new Map(arr.map(item => [item[key], item])).values()]
      }

      const arr1 = getUniqueListBy(this.Alerts, 'recommendation')
      console.log(arr1);

      this.isAdmin ? this.Alerted = this.Alerts : this.Alerted = arr1;
      console.log(this.Alerted);
    })

  }

  async sortData(allService: any[], isAsc: string, field: string) {
    let allData = this.deepCopy(allService)
    const asc = isAsc === "asc"
    const sortingBydate = (a: any, b: any) => {
      if (field == "testDate") {
        return asc ? this.converDatetoobj(a[field]).getTime() - this.converDatetoobj(b[field]).getTime()
          : this.converDatetoobj(b[field]).getTime() - this.converDatetoobj(a[field]).getTime()
      } else {
        return asc ? a[field] - b[field] : b[field] - a[field]
      }
    }
    const sortedData = allData.sort(sortingBydate)
    return allService.length > 0 ? sortedData : allService
  }

  converDatetoobj(date: any) {
    let ISOstring = moment(date.slice(5, 25).toUpperCase(), "DD MMM YYYY HH:mm:ss")
    return ISOstring.toDate()
  }

  async sortbyColumn(event: Sort) {
    const table_data = this.dataSource.data
    await this.sortData(table_data, event.direction, event.active).then(sorted_data => {
      this.dataSource = new MatTableDataSource<TableData>(sorted_data);
      this.dataSource.paginator = this.paginator;
    })
  }

  clearListdata() {
    this.serviceData = undefined
  }

  clearServicedata() {
    this.dataSource = undefined
  }

  highlight(row) {
    this.selectedRowIndex = row;
  }

  //orders data from array
  orderData(arrayofRows: any[]) {
    let orderedArray = []
    const takeParamnotinArray = () => {
      return arrayofRows.filter((element) => {
        return !this.paramsOrder.includes(element.parameters)
      })
    }
    this.paramsOrder.forEach(orderParam => {
      arrayofRows.forEach(element => {
        if (orderParam == element.parameters) {
          orderedArray.push(element)
        }
      });
    });
    return [].concat(...orderedArray, ...takeParamnotinArray());
  }

  clearTables(textBox: MatInput) {
    this.clearServicedata()
    this.clearListdata()
    this.maintenanceReport = undefined
    this.Alerts = []
    this.showAlerts = false
    textBox.value = ""
  }

  parameterFailedPercent(data: any[], param: ParamsCriteria) {
    console.log(data, param);
    let counter = 0
    let percent = 0
    if (param.startRange != undefined && data.length > 0) {
      data.forEach(element => {
        if (!(element[param.name] >= param.startRange && element[param.name] <= param.endRange)) {
          counter++
        }
      });
      percent = (counter / data.length) * 100
    }
    console.log(data.length, param, counter, percent);
    return { status: percent > (param.specificPercent ?? 10) ? true : false, percent: percent, ...param, }
  }

  dataFromrange(data: any[], startDate: Date) {
    return data.filter(el => new Date(this.converDatetoobj(el.testDate)) >= startDate)
  }
  async report() {
    console.log(this.facility, this.maintenanceReport.data.serviceDataParameters, await this.formatserviceData(this.maintenanceReport));
    this._report.maintenanceReport(this.facility, this.maintenanceReport.data.serviceDataParameters, await this.formatserviceData(this.maintenanceReport))
  }
  async report1() {
    this._report.getDocumentDefinition(this.facility, this.maintenanceReport.data.serviceDataParameters, await this.formatserviceData(this.maintenanceReport));
  }
  logout() {
    this._auth.signOut().then(res => {
      console.log("hii")
    })
  }

}

