<div class="container">
    <div mat-dialog-content class="selected" id="graphDiv" >
    </div>



  <div class="box mt-3">

  <div mat-dialog-actions class="right-align">
    <mat-form-field class="graph-select" appearance="outline">
      <mat-select disableOptionCentering [(ngModel)]="selectedValue" (selectionChange)="ChangeParameter()">
        <mat-option *ngFor="let opt of options" [value]="opt"> {{opt.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="push">
    <label class="label label-default" [hidden]="hideLabelInGraph">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      Calculating...
    </label>
    <!-- <span class="badge badge-primary" [hidden]="hideLabelInGraph">Calculating...</span> -->
    <button mat-raised-button class="ml-3 row" color="primary" matTooltip="Clear Graph and Show Trend Line" matTooltipPosition="above" style="height: fit-content; margin-top: 15px;" mat-button (click)="clearGraph()"><mat-icon>insert_chart</mat-icon></button>
    <button mat-raised-button class="ml-3 row" color="primary" matTooltip="Export Data" matTooltipPosition="above" style="height: fit-content; margin-top: 15px;" mat-button (click)="onExcelData()"><mat-icon>get_app</mat-icon></button>
    <button mat-raised-button class="ml-3 row" color="primary" matTooltip="Close" matTooltipPosition="above" style="height: fit-content; margin-top: 15px;" mat-button (click)="onNoClick()"><mat-icon>close</mat-icon></button>
 
  </div>


 </div>
</div>