import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";

import { CommonModule } from "@angular/common";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { HttpService } from "./http.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment"
import { MatSort, Sort } from "@angular/material/sort";

import { GraphDialogComponent } from 'src/app/graph-dialog/graph-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from "@angular/material/input";
import { version } from '../../package.json';
import { GlobalService } from "./global.service";
import { ReportingService } from "./service/reporting.service";
import { switchMap, retry, first, tap, map } from 'rxjs/operators';


// declare const $: any;

// interface TableData {
//   id: string;
//   testDate: string;
//   type: string;
//   SelfTest: string;
// }

// interface ParamsCriteria {
//   name: string;
//   title: string;
//   startRange?: number;
//   endRange?: number;
//   limitToCheck?: number
//   color: string;
//   specificPercent?: number
// }


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // title = 'servicedataviewer';
  // dataSource: MatTableDataSource<TableData>;
  // serviceData: MatTableDataSource<TableData>;
  // momentRef: any = moment
  // value = 'Clear me';
  // paramsOrder = ["SelfTest", "amplitude", "amplitudeAMP", "average", "aw", "controlZeroLevel", "ledCurrent1", "ledCurrent2", "maxSpWidth", "minSpHeight", "minSpWidth",
  //   "noiseLevel", "ref1", "ref2", "serialNumber", "zeroLevel"]
  // displayedColumns = ["detail", "testDate", "type", "SelfTest"];
  // parameterColumn = ["parameters", "value", "calibrated"]
  // restrictedTypes = ["Post-Production", "Final QC", "Pre-Calibration"]
  // isSpinner: boolean = false
  // paramsDecimals = {
  //   count: 1,
  //   ref2: 1,
  //   amplitude: 2,
  //   amplitudeAMP: 2,
  //   average: 2,
  //   averageSignalStd: 2,
  //   averageStd: 2,
  //   noiseLevelStd: 2,
  //   zeroLevel: 2,
  //   zeroLevelStdSignal: 2,
  // }
  // appVersion
  // checkingParams: ParamsCriteria[] = [
  //   { name: "SelfTest", title: "Self-Test", startRange: 1, endRange: 1, color: "#A10C0C" },
  //   { name: "SelfTest", title: "Self-Test", startRange: 1, endRange: 1, color: "#A10C0C", specificPercent: 2 },
  //   { name: "noiseLevel", title: "Noise Level", startRange: 0, endRange: 4, color: "#A10C0C" },
  //   { name: "ledCurrent1", title: "Led cur 1", limitToCheck: 2, color: "#A10C0C" },
  //   { name: "ledCurrent2", title: "Led cur 2", limitToCheck: 1, color: "#A10C0C" },
  //   { name: "ref2", title: "Ref2", startRange: 2700, endRange: 3300, color: "#A10C0C" }]

  // serviceDataofDevice: any = {}
  // @ViewChild(MatSort) sort: MatSort
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // selectedRowIndex: any;
  // postCalibrationData: any[] = []
  // Alerts: { parameter: string; status: boolean; percent: number }[] = [];
  // showAlerts: boolean = false
  // facility: any;
  // serviceDataforReport: any;
  // maintenanceReport: { data: { serviceDataParameters: any; }; };

  constructor() {
    // this.appVersion = version
  }
}




