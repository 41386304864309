export const titles = {
    ledCurrent1: "LED Cur. 1",
    ledCurrent2: "LED Cur. 2",
    ref1: "Ref. 1",
    ref2: "Ref. 2",
    zeroLevel: "Zero Level",
    odCap: "OD Capillary",
    odRef: "OD Reference",
    amplitude: 'Amplitude',
    average: 'Average',
    aw: 'AW',
    noiseLevel: 'Noise Level',
    averageSignalStd: 'Average Signal Std',
    averageStd: 'Average Std',
    count: 'Count',
    noiseLevelStd: 'Noise Level Std',
    od: 'OD',
    zeroLevelStdSignal: 'Zero Level Std.',
    amplitudeAMP: 'Amplitude Amp.',
    controlZeroLevel: 'Control Z.L.',
    minSpHeight: 'Min. Sp. Height',
    minSpWidth: 'Min. Sp. Width',
    maxSpWidth: 'Max. Sp. Width',
    LB_OD_AMP: 'LB OD Amp.',
    controlRef1: 'Control Ref. 1',
    controlRef2: 'Control Ref. 1',
    mscAmplification: 'MSC Amp.',
    signalFiltera: 'Signal Filter A',
    signalFilterk: 'Signal Filter K',
    smiThreshold: "SMI Thresh."

}

export interface user{
    email:string,
    password:string
}