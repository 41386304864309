import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from "../global.service";
import { formatDate } from '@angular/common';
import { HttpService } from './../http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import pdfMake from "../../assets/js/bundles/export-tables/pdfmake.min.js";
import Plotly from 'plotly.js-dist';
import * as XLSX from 'xlsx';
import * as firebase from "firebase/app";
import { SmartAnalysisService } from '../service/smart-analysis.service';


@Component({
  selector: 'app-graph-dialog',
  templateUrl: './graph-dialog.component.html',
  styleUrls: ['./graph-dialog.component.sass']
})
export class GraphDialogComponent implements OnInit {

  graph: any = {}
  dataURI = '';
  graphData: {}
  graphLayout = {}
  options: any;
  unsortedOptions: any;
  selectedValue: any = { name: '', value: '' };
  graphobj: any;

  graphdata = {};

  AllServiceData: any = {};
  newServiceData: any = {}

  minyAxis: any;
  maxyAxis: any;

  hideLabelInGraph: boolean = true;

  excelData: any = []

  //constructor(public dialogRef: MatDialogRef<GraphDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private _glob: GlobalService) {
  constructor(private _httpService: HttpService, private http: HttpClient, public dialogRef: MatDialogRef<GraphDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private global: GlobalService, private _analysis: SmartAnalysisService, private _glob: GlobalService) {
    this.AllServiceData = this.deepCopy(data);

    this.unsortedOptions = this.convertProperties(this.global.parProperties);
    console.log("Options", this.unsortedOptions);
    this.options = this.unsortedOptions.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    this.selectedValue = this.options[0]
    console.log("selectedValue", this.selectedValue);
    console.log("data from Main", this.AllServiceData);

  }

  // convert(obj) {
  //   return Object.keys(obj).map(key => (
  //     { 
  //       name: key,
  //       value: obj[key],
  //     }

  //   ));
  // }

  convertProperties(obj) {
    console.log("My parameters:", obj)
    return Object.keys(obj || {}).map(key => (
      {
        name: key,
        value: obj[key],
      }

    ));
  }


  ngOnInit(): void {
    console.log("Selected parameter ", this.selectedValue.name)
    this.hideLabelInGraph = true;
    this.initGraph();
    this.ChangeParameter();

  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  convertToPDF() {
    var definition = {
      info: {
        title: 'SDV',
        author: 'MES',
      },
      content: [
        {
          image: this.dataURI,
          width: 500,
          height: 400
        },
      ],
    }
    pdfMake.createPdf(definition).open();
  }
  
    public exportAsExcelFile(json: any[]): void {
      var worksheet: XLSX.WorkSheet; // generate worksheet
      var workbook: XLSX.WorkBook; // generate workbook
      worksheet = XLSX.utils.json_to_sheet(json);
      workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      let currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
      XLSX.writeFile(workbook, this.toExportFile("SDV_Clear_Graph_" + currentDate)); // save to file
    }
  
    toExportFile(excelFileName: string): string {
      return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
    }


  //get x and y data for graph generation
  public getData() {
    let x = [];
    let y = [];
    let objData = [];
    let axisData = { x, y };


    for (let i = 0; i < this.AllServiceData.length; i++) {
      // xaxis.push(Date.parse(this.AllServiceData[i].data?.serviceDataDate));
      // yaxis.push(this.AllServiceData[i].data.serviceDataParameters[this.selectedValue.name]);

      let coordinate = { x: Date.parse(this.AllServiceData[i].data?.serviceDataDate), y: this.AllServiceData[i].data.serviceDataParameters[this.selectedValue.name] }
      objData.push(coordinate);
    }
    //console.log("tmp not sort ", tmp)
    //console.log("tmp sort = ",tmp.sort((a,b)=> a.b.getTime() - b.b.getTime() ))
    //console.log("tmp sort = ",tmp.sort((a,b)=> a.a - b.a ))

    objData.sort((a, b) => {
      return a.x - b.x;
    })


    console.log("objData", objData)

    objData.forEach(item => {
      // let xData = item.x;
      // let yData = item.y;
      x.push(item.x);
      y.push(item.y);
    })

    console.log("axisData", axisData)
    return axisData
  }


  //get the value from the last Post Calibration Service Data
  public getRefValue(parameter) {

    let xaxisTimeStamp: number;
    let x1axisPost: number;
    let x1axisPostMax: any;
    let refvalue = 0;

    for (let i = 0; i < this.AllServiceData.length; i++) {

      xaxisTimeStamp = (Date.parse(this.AllServiceData[i].data?.serviceDataDate));

      if (this.AllServiceData[i].data?.serviceDataType == "Post-Production") {
        x1axisPost = (Date.parse(this.AllServiceData[i].data?.serviceDataDate));

      }

    }

    x1axisPostMax = Math.max(x1axisPost)
    console.log("MAX", x1axisPostMax);

    for (let i = 0; i < this.AllServiceData.length; i++) {
      if (x1axisPostMax == Date.parse(this.AllServiceData[i].data?.serviceDataDate)) {

        refvalue = this.AllServiceData[i].data?.serviceDataParameters[parameter]
        break;
      }
    }

    console.log("  Parameter = ", parameter, "   refvalue = ", refvalue)

    return refvalue;

  }

  //Inintiating the graph
  public initGraph() {


    let xaxis = [];
    let yaxis = [];
    let arr = [];

    var data1 = {
      x: xaxis,
      y: yaxis,
      type: 'scatter',
      mode: this.selectedValue.value.mode,
      showlegend: true,
      marker: {
        color: 'rgb(87, 80, 193)',
        size: this.selectedValue.value.markerSize
      },
      line: {
        color: 'rgb(87, 80, 193)',
        width: 0.3,
        dash: 'dot'
      }
    };

    var data2 = {
      x: xaxis,
      y: arr,
      type: 'scatter',
      showlegend: true,
      name: 'Post Calibration',
      mode: "lines",
      line: {
        color: "red",
        width: 0.5,
      },
    };

    var max = {
      x: xaxis,
      y: [],
      fill: "tonexty",
      fillcolor: "rgba(213, 255, 213, 0.3)",
      line: { color: "green" },
      name: "Ranges",
      showlegend: false,
      type: "scatter"
    }

    var min = {
      x: xaxis,
      y: [],
      fill: "tonexty",
      fillcolor: "rgba(213, 255, 213, 0.3)",
      line: { color: "green" },
      name: "Ranges",
      showlegend: false,
      type: "scatter"
    }

    this.graph = {
      data: [data1, data2, min, max],
      layout: {
        title: this.selectedValue.value.name + " vs Date",
        titlefont: {
          size: 20,
          color: 'rgb(87, 80, 193)',
        },
        responsive: true,
        xaxis: {
          title: { text: "Date", standoff: 50 },
          titlefont: {
            color: 'rgb(87, 80, 193)'
          },
          tickmode: 'array',
          type: 'date',
          autotick: false,
          tickformat: '%d %B %Y',
          ticklen: 8,
          showgrid: true,
          showticklabels: true,
        },
        yaxis: {
          automargin: true,
          title: { text: "Amplitude", standoff: 20 },
          titlefont: {
            color: 'rgb(87, 80, 193)'
          },
          autorange: true,
        },
        autosize: true,
        width: 975,
        height: 550,
        automargin: true,
        legend: {
          x: 1,
          // xanchor: 'right',
          y: 0.5
        }
      },
    };

    this.graphobj = Plotly.react('graphDiv', this.graph.data, this.graph.layout, { scrollZoom: true }).then((gd) => {
      console.log("gd", gd);
      return Plotly.toImage(gd);
    }).then((dataURI) => {
      console.log("data URI", { dataURI });
      this.dataURI = dataURI;
    });

  }

  //Regrawing graph when changing a parameter
  ChangeParameter() {

    let y1axis = [];
    let refvalue = this.getRefValue(this.selectedValue.name)

    let maxyAxis = 0;
    let minyAxis = 0;
    let showrange = false;

    //Generating range for each parameter
    if (this.selectedValue.value.range != undefined) {

      maxyAxis = this.selectedValue.value.range[1];
      minyAxis = this.selectedValue.value.range[0];

      showrange = true;
    }


    for (let i = 0; i < this.AllServiceData.length; i++) {
      y1axis.push(refvalue)
    }

    let xaxis = this.getData().x
    let yaxis = this.getData().y

    console.log("Min Max for graph", minyAxis, maxyAxis)

    var data1 = {
      x: xaxis,
      y: yaxis,
      type: 'scatter',
      mode: this.selectedValue.value.mode,
      showlegend: true,
      name: this.selectedValue.value.name,
      marker: {
        color: 'rgb(87, 80, 193)',
        size: this.selectedValue.value.markerSize
      },
      line: {
        color: 'rgb(87, 80, 193)',
        width: 0.3,
        dash: 'dot'
      }
    };

    var data2 = {
      x: xaxis,
      y: y1axis,
      type: 'scatter',
      showlegend: true,
      name: 'Post Calibration',
      mode: "lines",
      line: {
        color: "red",
        width: 0.5,
      },
    };

    var max = {
      x: [xaxis[0], xaxis[xaxis.length - 1]],
      y: [maxyAxis, maxyAxis],  //maxyAxisArray,
      fill: "tonextx",
      fillcolor: "rgba(213, 255, 213, 0.3)",
      line: { color: "transparent" },
      name: "Ranges",
      showlegend: false,
      type: "scatter",
      // visible: showrange,
    }

    var min = {
      x: [xaxis[0], xaxis[xaxis.length - 1]],
      y: [minyAxis, minyAxis],  //minyAxisArray,
      fill: "tonextx",
      fillcolor: "rgba(213, 255, 213, 0.3)",
      line: { color: "transparent" },
      name: "Ranges",
      showlegend: false,
      type: "scatter",
      // visible: showrange,
    }

    this.graph.data[2] = data1;
    this.graph.data[3] = data2;

    console.log(xaxis)
    console.log(yaxis)

    this.graph.data[0] = min;
    this.graph.data[1] = max;
    this.graph.data[4] = [];

    this.graph.layout.yaxis.title = { text: this.selectedValue.value.name, standoff: 20 };
    this.graph.layout.title = this.selectedValue.value.name + " vs Date";
    this.graph.layout.titlefont = { size: 20, color: 'rgb(87, 80, 193)' },
      this.graph.layout.xaxis.titlefont = { color: 'rgb(87, 80, 193)' },
      this.graph.layout.yaxis.titlefont = { color: 'rgb(87, 80, 193)' },
      // this.graph.layout.xaxis.range = [0, xaxis.length - 1]


      Plotly.redraw('graphDiv');

  }

  clearGraph() {
    this.hideLabelInGraph = false;
    let xaxis = []
    let yaxis = []
    let arrayData = this.getData()
    console.log("arrayData", arrayData)

    this._httpService.filterData(arrayData).subscribe(res => {
      console.log("res", res['array']);
      xaxis = res['array']['x']
      yaxis = res['array']['y']
      var clearData = {
        x: xaxis,
        y: yaxis,
        type: 'scatter',
        mode: this.selectedValue.value.mode,
        showlegend: true,
        name: this.selectedValue.value.name,
        marker: {
          color: 'rgb(87, 80, 193)',
          size: this.selectedValue.value.markerSize
        },
        line: {
          color: 'rgb(87, 80, 193)',
          width: 0.3,
          dash: 'dot'
        }
      }
    //---------------------------Bala's Edit----------------------------------------------------------
      const trentAxis = this._analysis.findLeastSquareLine(Object.assign({}, yaxis))
      const trendLineData = [trentAxis[0], trentAxis[trentAxis.length - 1]] // Alexandra's Edit
      console.log("trendLineData",trendLineData)
      console.log("xaxis",[xaxis[0], xaxis[xaxis.length - 1]])
      const trendline = {
        x: [xaxis[0], xaxis[xaxis.length - 1]], // Alexandra's Edit
        y: trendLineData,
        name: "Trend Line",
        type: 'scatter',
        mode: 'lines',
        line: {
          color: 'rgb(78,14,41)', 
          width: 1.5,
        },
        showlegend: true,
      }
      // Arrow in the graph
      // this.graph.layout.annotations = [{
      //     x: xaxis[(trentAxis.length / 2).toFixed()],
      //     y: trentAxis[(trentAxis.length / 2).toFixed()],
      //     xref: 'x',
      //     yref: 'y',
      //     text: 'Trend line',
      //     showarrow: true,
      //     arrowhead: 2,
      //     ax: 0,
      //     ay: -40
      //   }]


      this.graph.data[2] = clearData
      this.graph.data[4] = trendline

      //-----------------------------------Bala's edit end Here----------------------------------------------
      Plotly.redraw('graphDiv', this.graph.data, this.graph.layout);

      this.hideLabelInGraph = true;

    });

    return arrayData
  }


  deepCopy(datatoCopy) {
    return JSON.parse(JSON.stringify(datatoCopy))
  }


  onExcelData() { 
    let timestamp = firebase.default.firestore.Timestamp;
    let data = this.getData()
    console.log("onExcelData", data);
    let datatoExport = [];

    let data_keys = data.x
    let data_values = data.y

    for (var j = 0; j < data.x.length; j++) {

      let item = {};

      if (data.x[j] != undefined) {

        item["#"] = j + 1;
        item["X"] = new timestamp(data_keys[j] / 1000,0 ).toDate();
        item["Y"] = data_values[j];
      }

      datatoExport.push(item);
    }

    console.log("export exportAsExcelFile", datatoExport);

    this.exportAsExcelFile(datatoExport)
  }



}


