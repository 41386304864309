import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { GraphDialogComponent } from './graph-dialog/graph-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReportingService } from './service/reporting.service';
import { SmartAnalysisService } from './service/smart-analysis.service';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './main/main.component';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [
    AppComponent,
    GraphDialogComponent,
    MainComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    TooltipModule.forRoot(),
    MaterialModule,
    MatTooltipModule,
    CoreModule
  ],
  providers: [ReportingService, SmartAnalysisService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
