import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';


/*
export const ServiceDataParForGraph = {

  amplitude: "Amplitude",
  amplitudeAMP: "Amplitude AMP",
  average: "Average",
  averageSignalStd: "Average Signal STD",
  averageStd: "Average STD",
  aw: "Average Width",
  controlRef1: "Control Ref 1",
  controlRef2: "Control Ref 2",
  controlZeroLevel: "Control Zero Level ",
  count: "Count",
  ledCurrent1: "Led Current 1",
  ledCurrent2: "Led Current 2",
  od: "OD",
  odCap: "OD Capillary",
  odRef: "OD Reference",
  noiseLevel: "Noise Level",
  noiseLevelStd: "Noise Level STD",
  ref1: "Ref 1",
  ref2: "Ref 2",
  SelfTest: "Self Test",
  zeroLevelStdSignal: "Zero Level STD Signal",
  zeroLevel: "Zero Level"

}
*/

@Injectable({
  providedIn: 'root'
})
export class GlobalService {


  rangesData: any;
  parProperties: any;
  getdocument:any[];
  date:any[];
  getdocumentfunc:any;
  date1:any;
  excelData: any;
  
  constructor(private _httpService: HttpService) {


    // this.getRanges();
    this.getParproperties()

    

  }

  getParproperties() {

    this._httpService.getCollectionData("System", "selfTestParameter")
      .subscribe((data: any) => {
        this.parProperties = data.data.serviceDataViewer;

        console.log("parProperties",this.parProperties);
      })
  }


  






}

