import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SmartAnalysisService {

  constructor() { }

  findLeastSquareLine(graphData: any) {
    let sumOfx = 0
    let sumOfy = 0
    let sumOfxy = 0
    let sumOfxx = 0
    const xAxis = Object.keys(graphData)
    const yAxis = Object.values(graphData)
    const n = xAxis.length
    let finalDataPoints = []
    xAxis.forEach((el: any, index) => {
      sumOfx += Number(el)
      sumOfy += Number(yAxis[index])
      sumOfxy += Number(yAxis[index]) * el
      sumOfxx += Number(el) * Number(el)
    })
    console.log(xAxis,yAxis);
    
    console.log(sumOfx,sumOfy,sumOfxy,sumOfxx);

    //linear equalion y = mx+b
    //slope m = n*sum(xy) - sum(x)*sum(y)/n*sum(x^2) - sum(x)^2 where n is total points
    //intercept b = sum(y) - m*sum(x)/n

    const m = ((n * sumOfxy) - (sumOfx * sumOfy)) / ((n * sumOfxx) - Math.pow(sumOfx, 2))
    const b = ((sumOfy) - (m * sumOfx)) / n

    xAxis.forEach(element => {
      finalDataPoints.push((m * Number(element)) + b)
    });
    return finalDataPoints
  }
}
